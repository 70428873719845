import React from "react";

import Ready from "../ready/ready";
import WorkDone from "../get_started/work_done";
import Subscribe from "../subscribe/subscribe_2";
import TopBanner from "../top_banner/product_top_banner";
import { useTranslation } from 'react-i18next';

const ProductAnalysis = () => {
  const { t } =useTranslation();
  const analysisData = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/paper_with_chesse_icon_with_bg.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/user_with_graphs.svg",
      title: `${t('get_valuable_insights_instantly')}`,
      description: `${t('the_detailed_reports_designed_by_mereos')}`,
      button: {
        text: `${t('click_here_to_find_out_more')}`,
        href: ""
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/scope_on_book_icon_with_bg.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/scope_on_website.svg",
      title: `${t('analyze_and_optimize_your_content')}`,
      description: `${t('in_just_a_few_seconds_find_out_which_questions')}`,
      button: {
        text: `${t('click_here_to_find_out_more')}`,
        href: ""
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/pc_with_key_inside_icon_with_bg.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/form_with_server.svg",
      title: `${t('limited_access_to_assessment_data')}`,
      description: `${t('by_default_access_to_candidate_questions')}`,
      button: {
        text: `${t('click_here_to_find_out_more')}`,
        href: ""
      }
    },
  ];

  return (
    <>
      <div className={`conatiner font-sans`}>
        <TopBanner
          pageTitle="Analyse"
          title={t('better_interpretation_through_data_analysis')}
          description={t('get_a_better_understanding_of_your_learners')}
          imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/product_top_banner_girl_and_boy_with_laptop.svg"
          leftButton={{
            text: `${t("free_trials")}`,
            href: "/free-trial",
          }}
          rightButton={{
            text: `Demo >`,
            href: "/demo",
          }}
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-5/6 `}>
          <div className="my-24">
            <WorkDone
              title={t('turn_your_observations_into_actions')}
              subTitle={t('tools_to_find_identify_and_select_the_best_profiles')}
              data={analysisData}
              titleFull
              starGrid
            />
          </div>

          {/* Ready */}
          <div className="my-20">
            <Ready />
          </div>
        </div>
        <Subscribe />
      </div>
    </>
  );
};

export default ProductAnalysis;
