import * as React from "react";

import Layout from "../components/layout/layout";
import ProductAnalysisComponent from "../components/product-page/product_analysis";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const ProductAnalysis = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title="Analyse data"
        description={t('recruit_top_talent_with_our_state')}
      />
      <Layout>
        <ProductAnalysisComponent />
      </Layout>
    </>
  )
}

export default ProductAnalysis
